@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
body {
    margin: 0;
    font-family:"Open Sans";
    font-size: 15px;
    line-height: 22px;
}
body .MuiTooltip-arrow{
    color: #893f2f;
}
body .MuiTooltip-tooltipArrow {
    background-color: #893f2f;
    font-family: "Open Sans";
    font-size: 11px;
    color: #FFF;
    max-width: 100%;
    border-radius: 6px;
}
body .MuiTooltip-tooltipArrow .css-kudwh-MuiTooltip-arrow {
    color: #893f2f;
}
body.toggled-sideNav .sideNave-menu {
    min-width: initial;
    left: 0;
}
body.toggled-sideNav #main-content::before {
    opacity: 0.39;
}
body.toggled-sideNav .sideNave-menu .toggle-menu {
    right: 20px;
    opacity: 1;
    pointer-events: all;
}
.full-spinner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
}

.full-spinner.background-load {
    background: #FFF;
}

.full-spinner.md .loadingio-spinner-ripple {
    width: 104px;
    height: 104px;
}

.full-spinner.md .ldio {
    transform: translateZ(0) scale(1);
}

.full-spinner.md .ldio div {
    border-width: 2.08px;
    animation: ldio-md 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.full-spinner.xs .loadingio-spinner-ripple {
    width: 38px;
    height: 38px;
}

.full-spinner.xs .ldio {
    transform: translateZ(0) scale(0.38);
}

.full-spinner.xs .ldio div {
    border-width: 2px;
    animation: ldio-xs 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio div:nth-child(1) {
    border-color: #5E81F4;
    animation-delay: 0s !important;
}

.ldio div:nth-child(2) {
    border-color: #65757a;
    animation-delay: -0.5s !important;
}

.loadingio-spinner-ripple {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
}

.background-load .loadingio-spinner-ripple {
    background: #ffffffed;
}

.ldio {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}

.ldio div {
    box-sizing: content-box;
}



@keyframes ldio {
    0% {
        top: 96px;
        left: 96px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 18px;
        left: 18px;
        width: 156px;
        height: 156px;
        opacity: 0;
    }
}

@keyframes ldio-md {
    0% {
        top: 49.92px;
        left: 49.92px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 9.36px;
        left: 9.36px;
        width: 81.12px;
        height: 81.12px;
        opacity: 0;
    }
}

@keyframes ldio-xs {
    0% {
        top: 48px;
        left: 48px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 9px;
        left: 9px;
        width: 78px;
        height: 78px;
        opacity: 0;
    }
}

.bloc-sorTable-element .bloc-btn-sortField{
    text-align: center;
    margin-top: 28px;
}
